import cx from "classnames";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Brand } from "../../interfaces/types";
import { RootState } from "../../redux/store";
import IconButton from "../IconButton/IconButton";
import { StyledButton } from "../styled/styled-components";
import AgreementLinks from "./AgreementLinks/AgreementLinks";
import { allBrands } from "./BrandMenu/brands";
import GenericBrandMenu from "./BrandMenu/GenericBrandMenu";
import WebstoreMenu from "./WebstoreMenu";
import { Context, IContext } from "../../context/ContextApp";
import Icon from "../Icon/icon";
import ExternalLink from "../../assets/externalLink";

interface Props {
  collapseSidebar: () => void;
  isSidebarCollapsed: boolean;
}

const Sidebar = ({ isSidebarCollapsed, collapseSidebar }: Props) => {
  const history = useHistory();

  const brands = localStorage.getItem("brands");
  const jwt = localStorage.getItem("jwt");
  const sublimationWebsite =
    process.env.REACT_APP_SUBLIMATION_WEBSITE + "?jwt=" + jwt;
  const isEnableSublimation = process.env.REACT_APP_ENABLE_SUBLIMATION === "true";

  const [webstoreExpanded, setWebstoreExpanded] = useState(true);
  const [expandedGroup, setExpandedGroup] = useState("");

  const [currentBrand, setCurrentBrand] = useState<Brand | null>(null);
  const [currentCategory, setCurrentCategory] = useState("cart");

  const organization = useSelector((state: RootState) => state.organization);
  const { appBranch }: IContext = useContext(Context);

  const renderSelectedOfficeNameAndChangeButton = () => {
    if (
      organization.is_webstore_admin &&
      organization.selected_office != null
    ) {
      return (
        <div className="change-office-container">
          <div>
            Current Selected Office: <br />
            <b>{organization.selected_office.company_name}</b>
          </div>
          <StyledButton
            warning
            onClick={() => history.push("/branch-selection")}
          >
            Change Office
          </StyledButton>
        </div>
      );
    }
  };

  const renderBrandMenus = () => allBrands.map((brand, index) => (
    <GenericBrandMenu
      key={index}
      branch={appBranch}
      brandObject={brand}
      currentBrand={currentBrand}
      currentCategory={currentCategory}
      setCurrentBrand={() => setCurrentBrand(brand.name)}
      setCurrentCategory={setCurrentCategory}
      expandedGroup={expandedGroup}
      setExpandedGroup={setExpandedGroup}
      show={(brands && brands.indexOf(brand.name) > -1) === true}
    />
  ));

  return (
    <div
      className={cx({
        "sidebar-wrapper": true,
        collapsed: isSidebarCollapsed,
      })}
      aria-hidden={isSidebarCollapsed}
    >
      <div
        className={cx({
          sidebar: true,
          collapsed: isSidebarCollapsed,
        })}
      >
        {renderSelectedOfficeNameAndChangeButton()}
        <WebstoreMenu
          webstoreExpanded={webstoreExpanded}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          setWebstoreExpanded={setWebstoreExpanded}
        />
        {renderBrandMenus()}
        {isEnableSublimation && (
          <div className="sidebar-menu">
            <div
              className="menu-label"
            role="menubar"
            onClick={() => window.open(sublimationWebsite, "_blank")}
          >
            {"Biz Co.Lab"}
            <div className="expand-icon">
              <ExternalLink width={15} fill="white"/>
            </div>
            </div>
          </div>
        )}
        <AgreementLinks />
      </div>
      <div className="empty-space" onClick={collapseSidebar}>
        <div>
          <IconButton inline>
            <Icon name="TimesWhite" iconWidth="2rem" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
