import React from "react";
import { useHistory } from "react-router-dom";
import { Brand } from "../../../interfaces/types";
import { BrandMenuObject, Catalogue } from "./brands";
import GenericSubmenu from "./GenericSubmenu";
import { subdomainTypes } from "../../../context/availableSubdomains";
import PlusIcon from "../../../assets/plus";
import MinusIcon from "../../../assets/minusIcon";

interface Props {
  currentBrand: Brand | null;
  currentCategory: string;
  expandedGroup: string;
  branch: subdomainTypes | "";
  setCurrentBrand: React.Dispatch<React.SetStateAction<Brand | null>>;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
  setExpandedGroup: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
  brandObject: BrandMenuObject;
}

const GenericBrandMenu = ({
  currentBrand,
  currentCategory,
  expandedGroup,
  branch,
  setCurrentBrand,
  setCurrentCategory,
  setExpandedGroup,
  show,
  brandObject,
}: Props) => {
  const history = useHistory();

  const renderCatalogueList = (catalogueList?: Catalogue[]) => {
    if (catalogueList && catalogueList.length > 0) {
      const catalogueByBranch = catalogueList.filter(
        (item) => item.branch === branch
      );
      return catalogueByBranch.map((item, index) => (
        <a
          key={index}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Media Library
        </a>
      ));
    }
  };

  if (show) {
    const { name, title, tags, catalogueList  } = brandObject;
    const hasExpandedGroup = tags && tags.length > 0;

    return (
      <div className="sidebar-menu">
        <div
          className="menu-label"
          role="menubar"
          onClick={() => {
            setExpandedGroup(expandedGroup === name ? "" : name);
            if (!hasExpandedGroup) {
              setCurrentBrand(null);
              history.push(`/products/${name}?page=1`);
            }
          }}
        >
          {title}
          {hasExpandedGroup && (
            <div className="expand-icon">
              {expandedGroup === name ? (
                <MinusIcon fill="white" width={10}/>
              ) : (
                <PlusIcon fill="white" width={10} />
              )}
            </div>
          )}
        </div>
        {hasExpandedGroup && expandedGroup === name && (
          <div className="sub-menu">
            <GenericSubmenu
              branch={branch}
              tags={tags}
              brandName={name}
              currentBrand={currentBrand}
              currentCategory={currentCategory}
              setCurrentBrand={setCurrentBrand}
              setCurrentCategory={setCurrentCategory}
            />
            {renderCatalogueList(catalogueList)}
          </div>
        )}
      </div>
    );
  } else return <></>;
};

export default GenericBrandMenu;
