import { formatDisplayAddress } from "../../../../components/Address/util";
import { StyledFormField } from "../../../../components/styled/styled-components";
import { Address } from "../../../../interfaces/interfaces";

interface Props {
  savedAddresses: Address[];
  setSelectedAddress: React.Dispatch<React.SetStateAction<Address | null>>;
  setSelectValue: React.Dispatch<React.SetStateAction<number>>;
  selectValue: number;
}

const RenderAddressList = ({ savedAddresses, setSelectedAddress, setSelectValue, selectValue }: Props) => {
  const addressBook = savedAddresses.slice();

  const onAddressSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.target.value);

    const address = savedAddresses[index];
    setSelectedAddress(address);
    setSelectValue(index);

    if (selectValue > 0) {
      address.is_primary_address = false;
    }
  };

  return (
    <StyledFormField>
      <label>My Addresses</label>
      <select onChange={onAddressSelect} value={selectValue}>
        {addressBook.map((address, index) => (
          <option value={index} key={index}>
            {formatDisplayAddress(address)}
          </option>
        ))}
      </select>
    </StyledFormField>
  );
};

export default RenderAddressList;
