import cx from "classnames";
import { Link } from "react-router-dom";
import { Wrapper } from "../styled/styled-components";
import { Context, IContext } from "../../context/ContextApp";
import { useContext } from "react";
import Icon from "../Icon/icon";
import { IconNames } from "../Icon/types";
import MinusIcon from "../../assets/minusIcon";
import PlusIcon from "../../assets/plus";
import ExternalLink from "../../assets/externalLink";
import Tooltip from "../Tooltip/Tooltip";

interface Category {
  title: string;
  category: string;
  route: string;
  link?: string;
  isExternalLink?: boolean;
  icon: IconNames;
}

const CATEGORIES: Category[] = [
  {
    title: "Cart",
    category: "cart",
    route: "/store/cart",
    icon: "ShoppingCartWhite",
  },
  {
    title: "Quick Order",
    category: "quickorder",
    route: "/quick-order",
    icon: "Pencil",
  },
  {
    title: "Saved Orders",
    category: "savedorders",
    route: "/saved-orders",
    icon: "Save",
  },
  {
    title: "Order Import",
    category: "orderimport",
    route: "/order-uploader",
    icon: "FileUpload",
  },
  {
    title: "Order History",
    category: "orderhistory",
    route: "/order-history",
    icon: "BoxOpen",
  },
  {
    title: "Transactions",
    category: "transactions",
    route: "/transactions",
    icon: "CreditCard",
  },
  {
    title: "Preferences",
    category: "preferences",
    route: "/preferences",
    icon: "Cog",
  },
  {
    title: "Promotions",
    category: "promotions",
    route: "/store/cart?tab=promotions",
    icon: "StarWhite",
  },
  {
    title: "Consolidated Media Library",
    category: "medialibrary",
    route: "/media-library",
    link: "https://drive.google.com/drive/folders/1b0psR81rxlQPe_O8t4WkgyUHVx9Mq_i2?usp=drive_link",
    isExternalLink: true,
    icon: "PhotoVideo",
  },
];

const CATEGORIES_CA: Category[] = [
  {
    title: "Cart",
    category: "cart",
    route: "/store/cart",
    icon: "ShoppingCartWhite",
  },
  {
    title: "Quick Order",
    category: "quickorder",
    route: "/quick-order",
    icon: "Pencil",
  },
  {
    title: "Saved Orders",
    category: "savedorders",
    route: "/saved-orders",
    icon: "Save",
  },
  {
    title: "Order History",
    category: "orderhistory",
    route: "/order-history",
    icon: "BoxOpen",
  },
  {
    title: "Transactions",
    category: "transactions",
    route: "/transactions",
    icon: "CreditCard",
  },
  {
    title: "Preferences",
    category: "preferences",
    route: "/preferences",
    icon: "Cog",
  },
  {
    title: "Promotions",
    category: "promotions",
    route: "/store/cart?tab=promotions",
    icon: "StarWhite",
  },
  {
    title: "Media Library",
    category: "medialibrary",
    route: "/media-library",
    icon: "PhotoVideo",
  },
];

interface Props {
  webstoreExpanded: boolean;
  currentCategory: string;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
  setWebstoreExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebstoreMenu = ({
  webstoreExpanded,
  currentCategory,
  setCurrentCategory,
  setWebstoreExpanded,
}: Props) => {
  const { appBranch }: IContext = useContext(Context);
  const webstoreMenuLinks = appBranch === "ca" ? CATEGORIES_CA : CATEGORIES;

  const returnLinks = () => webstoreMenuLinks.map((item, index) => (
     item.isExternalLink && appBranch === 'nz' ? (
      <a className="icons" href={item.link} target="_blank" >
        <Wrapper style={{ width: "15%"}}>
          <Icon name={item.icon} />
        </Wrapper>
         <Tooltip  tooltipClassName="tooltip"content={"Check out our NEW Media Library layout, with everything you need in one easy-to-navigate location"}>{item.title}</Tooltip>
        <div className='expand-icon' style={{marginRight:'0.5rem'}}>
          <ExternalLink width={15} fill="white"/>
        </div>
      </a>)
      :
      (<Link
      key={index}
      className={cx({
        selected: webstoreExpanded && currentCategory === item.category,
        icons: true,
      })}
      to={item.route}
      onClick={() => {
        setCurrentCategory(item.category);
      }}
    >
      <Wrapper style={{ width: "15%" }}>
        <Icon name={item.icon} />
      </Wrapper>
      {item.title}
    </Link>)
  ));
  return (
    <div className="sidebar-menu">
      <div
        className="menu-label"
        onClick={() => setWebstoreExpanded(!webstoreExpanded)}
      >
        Webstore
        <div className="expand-icon">
          {webstoreExpanded ? (
            <MinusIcon width={10} fill="white" />
          ) : (
            <PlusIcon width={10} fill="white" />
          )}
        </div>
      </div>
      {webstoreExpanded && <div className="sub-menu">{returnLinks()}</div>}
    </div>
  );
};

export default WebstoreMenu;
