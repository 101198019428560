import { useContext } from "react";
import { Context, IContext } from "../../context/ContextApp";
import { Container } from "../../components/styled/styled-components";
import { useDocumentTitle } from "../../hooks/document-title";

const auItems = [
  {
    title: "Biz Collection",
    url: "https://drive.google.com/drive/folders/13t9zRVtdPpMTj87sNt_BVSqxHQT_5VLi"
  },
  {
    title: "Biz Corporates",
    url: "https://drive.google.com/drive/folders/1xnPCRpjfVxcdaWOCctbEBayz0UudJK5M"
  },
  {
    title: "Syzmik",
    url: "https://drive.google.com/drive/folders/1f7fQEQB6hdp60DriBOfKXZd3k4TkDaZ1"
  },
  {
    title: "Biz Care",
    url: "https://drive.google.com/drive/folders/1B_72PFjxtlmiFJXWSwYMBt2K2M4xHM_B"
  }
];

const caItems = [
  {
    title: "Biz Collection",
    url: "https://drive.google.com/drive/folders/1-1h94BIyqObMAQh8xBYJIAFOEeAlGf_Y"
  },
  {
    title: "Biz Corporates",
    url: "https://drive.google.com/drive/folders/1Q2ny_83rdtI2BLye5JtjpVMYCjHeIqOA"
  },
  {
    title: "Syzmik",
    url: "https://drive.google.com/drive/folders/1Ve0b073ttbRsB_bpEYV7RPbeeGm9mRJy?usp=drive_link"
  }
];

const MediaLibrary = () => {
  useDocumentTitle("Media library");
  const { appBranch }: IContext = useContext(Context);
  const itemsPerBranch = {
    "nz": [],
    "au": auItems,
    "ca": caItems,
    "": []
  }
  const items = itemsPerBranch[appBranch]

  return (
    <Container margin="1em 0" className="media-library">
      <h1>Media Library</h1>
      <div className="library-links">
        <ul>
          {items.map((item, index) => (
            <li>
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default MediaLibrary;
