import { Address, OrderSplitAPIResponse } from "../../../interfaces/interfaces";
import { FreightOption } from "../../../interfaces/types";

export const formatItemsSplitParams = (order: any) => {
  const items: any[] = [];
  if (order) {
    if (order.order_items) {
      order.order_items.forEach((oi: any) => {
        items.push({
          backorder_quantity: oi.backorder_quantity,
          id: oi.id,
          color: oi.color,
          itemNo: oi.formatted_item_no,
          price: oi.price,
          quantity: oi.quantity,
          reference: oi.reference,
          size: oi.size,
          sku: oi.sku,
        });
      });
    }
  }
  return items;
};

export const formatOrderSplitParams = (
  address: Address,
  freightOption: FreightOption,
) => {
  if (address) {
    const orderDetail = {
      // order_rails_id: id,
      is_primary_address: address.is_primary_address || false,
      address_city: address.address_city,
      address_contact: address.address_contact,
      address_country: address.address_country,
      address_fax: address.address_fax,
      address_line1: address.address_line1,
      address_line2: address.address_line2,
      address_line3: address.address_line3,
      address_line4: address.address_line4,
      address_phone: address.address_phone,
      address_postcode: address.address_postcode,
      address_state: address.state ?? address.address_state,
      freight_option: freightOption,
      ship_code: address.ship_code,
      ship_to_name: address.ship_to_name,
    } as Address;

    if (address.dpid) {
      orderDetail.dpid = address.dpid;
    }

    return { order_detail: orderDetail };
  } else {
    return null;
  }
};

export const getSubmitOrderParams = (
  currentOrderShipAddress: any,
  splitAPIResponse: any,
  extraInfo: any,
  freightOption: FreightOption
): any => {
  const params = {
    is_primary_address: currentOrderShipAddress.is_primary_address || false,
    // ship_code: currentOrderShipAddress.ship_code,
    split: splitAPIResponse.length > 0,
    // ship_to_name: currentOrderShipAddress.ship_to_name,
    // address_line1: currentOrderShipAddress.address_line1,
    // address_line2: currentOrderShipAddress.address_line2,
    // address_line3: currentOrderShipAddress.address_line3,
    // address_line4: currentOrderShipAddress.address_line4,
    // address_city: currentOrderShipAddress.address_city,
    // address_state:
    //   currentOrderShipAddress.state ?? currentOrderShipAddress.address_state,
    // address_postcode: currentOrderShipAddress.address_postcode,
    // address_country: currentOrderShipAddress.address_country,
    // address_phone: null,
    // address_fax: null,
    // address_contact: null,
    note: extraInfo.comments,
    // po_number: extraInfo.po_number,
    // freight_fee: splitAPIResponse.current_order.payment_details.shipping_cost,
    discount_by_freight: 0,
    freight_option: freightOption,
    courier: null,
    comment: extraInfo.comments,
    associated_emails: [extraInfo.associated_email],
    // items: splitAPIResponse.result.items,
  };

  return params;
};

// Don't remember why, but for some reason we need this function to enable the cart images on the checkout page
export const mergeData = (responseData: OrderSplitAPIResponse) => {
  const { result, current_order } = responseData;

  if (!responseData && !current_order && !result) {
    return responseData;
  }

  let currentOrderItems = current_order.order_items.slice();
  let resultItems: any[] = result.items.slice();

  currentOrderItems.forEach((item, index) => {
    item.index = index.toString();
  });

  /* Sort the items so they're in the same order */
  resultItems = sortItems(resultItems);
  currentOrderItems = sortItems(currentOrderItems);

  for (let i = 0; i < resultItems.length; i++) {
    resultItems[i].images = currentOrderItems[i].images;
    resultItems[i].color_images = currentOrderItems[i].color_images;
    resultItems[i].brand = currentOrderItems[i].brand;
    resultItems[i].product = currentOrderItems[i].product;
    resultItems[i].product_code = currentOrderItems[i].product_code;
    resultItems[i].product_name = currentOrderItems[i].product_name;
  }

  /* Revert the order */
  for (let i = 0; i < currentOrderItems.length; i++) {
    resultItems[i].index = currentOrderItems[i].index;
  }
  resultItems.sort((a, b) => a.index - b.index);

  return {
    ...responseData,
    result: {
      ...responseData.result,
      items: resultItems,
    },
  };
};

const sortItems = (items: any[]) => {
  items.sort((a, b) => parseInt(a.sku) - parseInt(b.sku));

  items.sort((a, b) => a.quantity - b.quantity);

  items.sort((a, b) => {
    if (a.reference > b.reference) {
      return 1;
    }
    if (a.reference < b.reference) {
      return -1;
    }
    return 0;
  });

  items.sort((a, b) => {
    if (a.size > b.size) {
      return 1;
    }
    if (a.size < b.size) {
      return -1;
    }
    return 0;
  });

  items.sort((a, b) => {
    if (a.color > b.color) {
      return 1;
    }
    if (a.color < b.color) {
      return -1;
    }
    return 0;
  });

  return items;
};
