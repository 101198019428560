import AddressSearcher, {
  Address,
} from "../../../components/Address/AddressSearcher";
import { StyledFormField } from "../../../components/styled/styled-components";
import { getAddressDetail } from "../../check-out/shipping-selection/AU/AddressOptions/NewAddress/GooglePlaceAPI";

export interface Form {
  state?: string;
  ship_to_name: string;
  address_line1: string;
  address_line2: string;
  address_line3: string;
  address_line4: string;
  address_city: string;
  address_postcode: string;
  address_country: string;
  is_primary_address?: boolean;
}

interface IProps {
  form: Form;
  onFormChange: (form: Form) => void;
}

const AddressForm = (props: IProps) => {
  const { form, onFormChange } = props;

  const clearDetailAddress = () => {
    onFormChange({
      ...form,
      address_city: "",
      address_postcode: "",
      state: "",
    });
  };

  const onChangeSearch = async (address: Address) => {
    const addressDetail = await getAddressDetail(address?.placeId || "");

    onFormChange({
      ...form,
      address_line1:
        address.FullAddress.charAt(0).toUpperCase() +
        address.FullAddress.slice(1),
      address_city: addressDetail.city,
      address_postcode: addressDetail.post_code,
      state: addressDetail.state,
    });
  };

  return (
    <section>
      <StyledFormField>
        <div role="input">
          <label>Ship to name</label>
          <input
            name={"ship_to_name"}
            value={form?.ship_to_name}
            onChange={(event) => {
              onFormChange({
                ...form,
                ship_to_name: event.target.value,
              });
            }}
          />
        </div>
        <div role="search">
          <AddressSearcher
            role="search"
            label={"Ship Address Line 1"}
            defaultValue={form?.address_line1}
            clearForm={clearDetailAddress}
            setSearchValue={(address: Address) => onChangeSearch(address)}
          />
        </div>

        <div role="input">
          <label>Ship Address Line 2</label>
          <input
            name={"address_line2"}
            value={form?.address_line2}
            onChange={(event) => {
              onFormChange({
                ...form,
                address_line2: event.target.value,
              });
            }}
          />
        </div>

        <div role="input">
          <label>City</label>
          <input name={"city"} disabled value={form?.address_city} />
        </div>

        <div role="input">
          <label>State</label>
          <input name={"state"} disabled value={form?.state} />
        </div>

        <div role="fixed">
          <label>Country</label>
          <span>Australia</span>
        </div>

        <div role="input">
          <label>Post Code</label>
          <input name={"post_code"} disabled value={form?.address_postcode} />
        </div>
      </StyledFormField>
    </section>
  );
};

export default AddressForm;
