import { useContext, useEffect, useRef, useState } from "react";
import { generateAuthHeader } from "../../../../commonfunctions/authentication";
import { log } from "../../../../commonfunctions/logger";
import { formatShipParamsForSave } from "../../../../components/Address/util";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  StyledButton,
  StyledFormLayout,
} from "../../../../components/styled/styled-components";
import Toast from "../../../../components/Toast/Toast";
import { Context, IContext } from "../../../../context/ContextApp";
import { AlertType } from "../../../../interfaces/enums";
import { Address, AlertContent } from "../../../../interfaces/interfaces";
import RenderAddressList from "./RenderAddressList";
import RenderSelectedAddress from "./RenderSelectedAddress";
import AUAddressForm, { Form } from "../AUAddressForm";

const EditableAddressBook = () => {
  const [savedAddresses, setSavedAddresses] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectValue, setSelectValue] = useState<number>(0);
  const [toast, setToast] = useState<AlertContent | null>(null);
  const { appBranch: branch }: IContext = useContext(Context);
  const [form, setForm] = useState<Form>({
    address_city: "",
    address_postcode: "",
    state: "",
    ship_to_name: "",
    address_line1: "",
    address_line2: "",
    address_line3: "",
    address_line4: "",
    address_country: "Australia",
    is_primary_address: false,
  });

  const isMounted = useRef(true);

  useEffect(() => {
    fetchSavedAddresses();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* GENERAL FUNCTION STARTS */
  const fetchSavedAddresses = async () => {
    setIsLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/saved`,
        generateAuthHeader()
      );
      const data = await res.json();
      if (isMounted.current) {
        setIsLoading(false);
        if (data.length === 0) {
          setToast({ message: "No addresses found.", type: AlertType.Warning });
        } else {
          const addresses: Address[] = data;

          addresses.sort((a, b) => {
            if (a.ship_to_name > b.ship_to_name) return 1;
            if (b.ship_to_name > a.ship_to_name) return -1;
            return 0;
          });
          setSelectValue(0);
          setSavedAddresses(addresses);
          setSelectedAddress(addresses[0]);
          setForm({...form, ...addresses[0]});
        }
      }
    } catch (error) {
      if (isMounted.current) {
        log(error, "Failed to fetch saved addresses in /preferences");
        setIsLoading(false);
        setToast({
          message: "Failed to load addresses.",
          type: AlertType.Error,
        });
      }
    }
  };

  const deleteAddress = async () => {
    if (!window.confirm("Are you sure you want to delete this address?")) return;

    if (selectedAddress) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/delete`,
          {
            method: "POST",
            body: JSON.stringify({ address_id: selectedAddress.id }),
            headers: {
              ...generateAuthHeader().headers,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.ok) {
          const data = await res.json();
          setSavedAddresses(data.ship_addresses);
          setSelectedAddress(data.selected_address);
          setToast({
            message: "Address successfully deleted.",
            type: AlertType.Success,
          });
          if (!data.ship_addresses.length) {
            setToast({
              message: "No addresses found.",
              type: AlertType.Warning,
            });
          }
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to delete address in /preferences");
        setToast({
          message: "Failed to delete address.",
          type: AlertType.Error,
        });
      }
    }
  };

  const updateAddress = async () => {
    if (selectedAddress) {
      const newAddress = branch === "au" ? form : selectedAddress;
      const params = formatShipParamsForSave(newAddress, branch);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/update`,
          {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
              ...generateAuthHeader().headers,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.ok) {
          fetchSavedAddresses();
          setToast({
            message: "Successfully updated address.",
            type: AlertType.Success,
          });
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to edit address in /preferences");
        setToast({
          message: "Failed to update address.",
          type: AlertType.Error,
        });
      }
    }
  };

  if (isLoading) {
    return (
      <div className="center-spinner">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {savedAddresses.length > 0 && (
        <div className="address-book-editor">
          <StyledFormLayout>
            <RenderAddressList
              savedAddresses={savedAddresses}
              setSelectedAddress={(newSelectedAddress) => {
                setSelectedAddress(newSelectedAddress);
                setForm({ ...form, ...newSelectedAddress });
              }}
              setSelectValue={setSelectValue}
              selectValue={selectValue}
            />

            {selectedAddress &&
              (branch === "au" ? (
                <AUAddressForm
                  form={form}
                  onFormChange={(newForm) => setForm(newForm)}
                />
              ) : (
                <RenderSelectedAddress
                  selectedAddress={selectedAddress}
                  branch={branch}
                  setSelectedAddress={setSelectedAddress}
                />
              ))}
          </StyledFormLayout>
          <div className="button-container">
            <StyledButton
              primary
              onClick={() => {
                updateAddress();
              }}
            >
              Update
            </StyledButton>
            <StyledButton
              danger
              onClick={() => {
                deleteAddress();
              }}
            >
              Delete
            </StyledButton>
          </div>
        </div>
      )}
      <Toast content={toast} onClick={() => setToast(null)} />
    </>
  );
};

export default EditableAddressBook;
