import { Address } from "../../../../interfaces/interfaces";
import { StyledFormField } from "../../../../components/styled/styled-components";
import AddressStateOptionSelector from "../../../../components/Address/AddressStateOptionSelector";
import { getAddressParams } from "../../../../components/Address/util";
import AddressSearcher, { Address as AddressSearcherType } from "../../../../components/Address/AddressSearcher";


interface Props {
  selectedAddress: Address;
  branch: string;
  setSelectedAddress: React.Dispatch<React.SetStateAction<Address | null>>;
}

const RenderSelectedAddress = ({ selectedAddress, branch, setSelectedAddress }: Props) => {

  const isAddressSearcher = (object: any): object is AddressSearcherType =>
    "FullAddress" in object;

  const onFieldChange = async (
    event: React.ChangeEvent<HTMLInputElement> | AddressSearcherType,
    key: string
  ) => {
    // If we're setting the address returned from the address searcher
    if (isAddressSearcher(event)) {
        const formNZ = getAddressParams(event.FullAddress, selectedAddress.ship_to_name)
        setSelectedAddress({
          ...selectedAddress,
          address_line1: formNZ ? formNZ.address_line1 : "",
          address_line4: formNZ ? formNZ.address_line4 : "",
          address_city: formNZ ? formNZ.address_city : "",
          address_postcode: formNZ ? formNZ.address_postcode : "",
          state: formNZ ? formNZ.state : "",
          dpid: event.DPID ? event.DPID : null,
        });
      // Else if setting ship_to_name
    } else {
      setSelectedAddress({
        ...selectedAddress,
        [key]: event.target.value,
      });
    }
  };

  const editFormField = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    key: string
  ) => {
    if (selectedAddress) {
      setSelectedAddress({
        ...selectedAddress,
        [key]: event.target.value
      });
    }
  };

  return(
    <>
      <StyledFormField>
        <label>Ship To Name</label>
        <input
          value={selectedAddress.ship_to_name}
          onChange={(event) => editFormField(event, "ship_to_name")}
        />
      </StyledFormField>
      <StyledFormField>

        {branch !== "nz" ? (
          <>
            <label>Ship Address</label>
            <input
            value={selectedAddress.address_line1}
            onChange={(event) => editFormField(event, "address_line1")}
            />
          </>) : (
            <AddressSearcher
              role="search"
              value={selectedAddress.address_line1}
              clearForm={() => ""}
              label="Ship Address"
              setSearchValue={(address: AddressSearcherType) =>
                onFieldChange(address, "address")
              }
          />
          )}
      </StyledFormField>

      <StyledFormField>
        {branch !== "nz" ? (
          <>
            <label>Ship Address Line 2</label>
            <input
              value={selectedAddress.address_line2}
              onChange={(event) =>
                editFormField(event, "address_line2")
              }
            />
          </>
        ) : (
          <>
            <label>Suburb</label>
            {selectedAddress.address_line4 || "-"}
          </>
        )}
      </StyledFormField>

      <StyledFormField>
        <label>City</label>
        {branch !== "nz" ? (
          <input
            value={selectedAddress.address_city}
            onChange={(event) => editFormField(event, "address_city")}
          />
        ) : (
          <span>{selectedAddress.address_city}</span>
        )}
      </StyledFormField>

      {branch !== "nz" && selectedAddress && selectedAddress.state && (
        <StyledFormField>
          {/* Canada uses "Province", Australia uses "State" */}
          <label>{branch === "ca" ? "Province" : "State"}</label>

          <AddressStateOptionSelector
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              editFormField(event, "state")
            }
            selectedValue={selectedAddress.state}
          />
        </StyledFormField>
      )}

      <StyledFormField>
        <label>Country</label>
        {branch !== "nz" ? (
          <input
            value={selectedAddress.address_country}
            onChange={(event) => editFormField(event, "address_country")}
          />
        ) : (
          <span>{selectedAddress.address_country}</span>
        )}
      </StyledFormField>

      <StyledFormField>
        <label>Post Code</label>
        {branch !== "nz" ? (
          <input
            value={selectedAddress.address_postcode}
            onChange={(event) => editFormField(event, "address_postcode")}
          />
        ) : (
          <span>{selectedAddress.address_postcode}</span>
        )}
      </StyledFormField>

    </>
  );
};

export default RenderSelectedAddress;
