import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldGenerator from "../../../../../../components/Address/AddressFormFieldGenerator";
import { getAddressParams } from "../../../../../../components/Address/util";
import AuthenticationHOC from "../../../../../../components/AuthenticationHOC/AuthenticationHOC";
import { Address as AddressSearcher } from "../../../../../../components/Address/AddressSearcher";
import {
  Container,
  StyledFormLayout,
} from "../../../../../../components/styled/styled-components";
import { Address } from "../../../../../../interfaces/interfaces";
import { updateFreightOption } from "../../../../../../redux/actions/orders";
import { RootState } from "../../../../../../redux/store";

interface Props {
  updateShippingAddressInRedux: (a: Address | null) => void;
}

interface Form {
  ship_to_name: string;
  address: string;
  dpid?: number | null;
}

function NewAddress({ updateShippingAddressInRedux }: Props) {
  const [form, setForm] = useState<Form>({ ship_to_name: "", address: "" });
  const dispatch = useDispatch();
  const shippingAddress = useSelector(
    (state: RootState) => state.orders.currentOrderShipAddress
  );

  useEffect(() => {
    if (form.ship_to_name && form.address) {
      const addressNZ = getAddressParams(form.address, form.ship_to_name);
      const newAddress = {
        ...addressNZ,
        dpid: form.dpid || null,
      } as Address;
      updateShippingAddressInRedux(newAddress);
    } else if (!form.ship_to_name || !form.address) {
      updateShippingAddressInRedux(null);
      dispatch(updateFreightOption(null));
    }
  }, [form, updateShippingAddressInRedux, dispatch]);

  const isAddressSearcher = (object: any): object is AddressSearcher =>
    "FullAddress" in object;

  const onFieldChange = (
    event: React.ChangeEvent<HTMLInputElement> | AddressSearcher,
    key: string
  ) => {
    // If we're setting the address returned from the address searcher
    if (isAddressSearcher(event)) {
      setForm({
        ...form,
        [key]: event.FullAddress,
        dpid: event.DPID ? event.DPID : null,
      });
      // Else if setting ship_to_name
    } else {
      setForm({
        ...form,
        [key]: event.target.value,
      });
    }
  };

  const checkoutBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(updateFreightOption("pickup"));
    } else {
      dispatch(updateFreightOption(null));
    }
  };

  return (
    <div className="new-address-wrapper">
      <StyledFormLayout>
        <FieldGenerator form={form} onChange={onFieldChange} />
      </StyledFormLayout>
      {shippingAddress && (
        <Container row>
          <b>Third Party Pick Up</b>
          <div style={{ marginLeft: "1em" }}>
            <input
              type="checkbox"
              style={{ width: "auto", marginRight: "10px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                checkoutBox(event)
              }
            />
            <span style={{ color: "red" }}>
              *Using a 3rd party to collect this order? Click here.
            </span>
          </div>
        </Container>
      )}
    </div>
  );
}

export default AuthenticationHOC(NewAddress);
