import { SVGProps } from "react"
const MinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 118.8 14.8"
    {...props}
  >
    <path d="M59.3 14.8H4.6c-.9 0-1.8-.1-2.7-.2-1.3-.1-1.8-.9-1.8-2.1V6.7c0-1.5-.1-3-.1-4.6C0 1 .5.2 1.7.1 2.5.1 3.2 0 4 0h110.4c.8 0 1.6.1 2.3.2 2 .3 2.2.4 2.2 2.4v10c0 1.2-.6 1.9-1.8 1.9-1.4.1-2.8.2-4.2.2-17.9 0-35.8 0-53.6.1 0-.1 0 0 0 0z" />
  </svg>
)
export default MinusIcon
