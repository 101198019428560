import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Address, AlertContent } from "../../../../../../interfaces/interfaces";
import { IContext, Context } from "../../../../../../context/ContextApp";
import useAddressFetch from "../../../../../../hooks/address-fetch";
import { updateFreightOption } from "../../../../../../redux/actions/orders";
import { formatDisplayAddress, formatShipParamsForSave, getAddressParams } from "../../../../../../components/Address/util";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import AuthenticationHOC from "../../../../../../components/AuthenticationHOC/AuthenticationHOC";
import { StyledFormField } from "../../../../../../components/styled/styled-components";
import { Address as AddressList } from "../../../../../../components/Address/AddressSearcher";
import Toast from "../../../../../../components/Toast/Toast";
import { AlertType } from "../../../../../../interfaces/enums";

interface Props {
  generateAuthHeader: any;
  updateShippingAddressInRedux: (a: Address | null) => void;
  clearShippingAddressInRedux: () => void;
}

const AddressBookNZ = ({
  generateAuthHeader,
  updateShippingAddressInRedux,
  clearShippingAddressInRedux
}: Props) => {
  const { savedAddresses, addressLoadCode } = useAddressFetch(generateAuthHeader);
  const [selectValue, setSelectValue] = useState(0);
  const dispatch = useDispatch();
  const { appBranch }: IContext = useContext(Context);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState<AddressList[]>([]);
  const [toast, setToast] = useState<AlertContent | null>(null);

  useEffect(() => {
    if (savedAddresses.length > 0) {
      setSelectValue(0);
      savedAddresses[0].is_primary_address = false;
      if (savedAddresses[0].dpid) {
        updateShippingAddressInRedux(savedAddresses[0]);
      } else {
        callAPI(formatDisplayAddress(savedAddresses[0]));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAddresses, updateShippingAddressInRedux]);

  const checkoutBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(updateFreightOption("pickup"));
    } else {
      dispatch(updateFreightOption(null));
    }
  };

  const callAPI = async (input: string) => {
    const url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/address_check?address_line_1=${input}`;

    try {
      const res = await fetch(url, generateAuthHeader());
      const data = await res.json();
      if (data.result.length && data.status === 'success') {
        setAddresses(data.result);
        setIsLoading(false);
      } else {
        setErrorMessage("This is not a valid NZPOST Address");
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again");
      setIsLoading(false);
    }
  };

    const updateAddress = async (address : Address) => {
        const params = formatShipParamsForSave(address, appBranch);

        try {
          const res = await fetch(
            `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses/update`,
            {
              method: "POST",
              body: JSON.stringify(params),
              headers: {
                ...generateAuthHeader().headers,
                "Content-Type": "application/json"
              }
            }
          );

          if (res.ok) {
            setToast({
              message: "Successfully updated address.",
              type: AlertType.Success
            });
          } else {
            const error = await res.text();
            throw error;
          }
        } catch (error) {
          setToast({
            message: "Failed to update address.",
            type: AlertType.Error
          });
        }
    };

  const selectAndSaveAddress = (address: AddressList) => {
    const currentdAddress = savedAddresses[selectValue]
    const formNZ = getAddressParams(address.FullAddress, currentdAddress.ship_to_name)
    currentdAddress.address_line1 = formNZ ? formNZ.address_line1 : "",
    currentdAddress.address_line4 = formNZ ? formNZ.address_line4 : "",
    currentdAddress.address_city = formNZ ? formNZ.address_city : "",
    currentdAddress.address_postcode = formNZ ? formNZ.address_postcode : "",
    currentdAddress.state = formNZ ? formNZ.state : "",
    currentdAddress.dpid = address.DPID
    updateAddress(currentdAddress)
    updateShippingAddressInRedux(currentdAddress)
  }

  const onAddressSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value);
    setSelectValue(selectedValue);

    /* Ignore blank value */
    const address = savedAddresses[selectedValue];

    address.is_primary_address = false;
    if (address.dpid) {
      updateShippingAddressInRedux(address);
    } else {
      clearShippingAddressInRedux()
      setIsLoading(true)
      callAPI(formatDisplayAddress(address));
    }

    // Reset freight option when selecting new address for non-AU branches
    const checkbox = document.getElementById("pickup") as HTMLInputElement | null
    dispatch(updateFreightOption(null));
    if (checkbox != null) {
      checkbox.checked = false;
    }
  };

  const confirmYourAddress = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (errorMessage) {
      <span>{errorMessage}</span>;
    }

    return (
      addresses.length > 0 && (
        <div className="address-searcher" style={{ marginLeft: 0, width: "100%" }}>
          {addresses.map((address: AddressList, index) => (
            <StyledFormField key={index}>
              <div
                className="address-suggestion"
                role="option"
                onClick={() => selectAndSaveAddress(address)}
              >
                {address.FullAddress}
              </div>
            </StyledFormField>
          ))}
        </div>
      )
    )
  };

  const renderAddressSelector = () => {
    const addressBook = savedAddresses.slice();

    return (
      <select
        className="form-control"
        onChange={(event) => onAddressSelect(event)}
        value={selectValue}
        data-testid="select"
      >
        {addressBook.map((address: Address, index: number) => (
          <option value={index} key={index}>
            {formatDisplayAddress(address)}
          </option>
        ))}
      </select>
    );
  };

  const renderSavedAddressesForm = () => {
    const selectedAddress = savedAddresses[selectValue] ?? ({} as Address);

    return (
      <div className="addressTableWrapper">
        <table className="addressTable">
          <tbody>
            <tr>
              <th>My Addresses</th>
              <td>{renderAddressSelector()}</td>
            </tr>
            {selectedAddress && (
              <>
                <tr>
                  <th>Ship To Name</th>
                  <td>{selectedAddress.ship_to_name}</td>
                </tr>
                <tr>
                  <th>Ship Address</th>
                  <td>{selectedAddress.address_line1}</td>
                </tr>
                  <tr>
                    <th>Suburb</th>
                    <td>{selectedAddress.address_line4}</td>
                  </tr>

                <tr>
                  <th>Ship Address Line 2</th>
                  <td>{selectedAddress.address_line2}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{selectedAddress.address_city}</td>
                </tr>


                <tr>
                  <th>Country</th>
                  <td>{selectedAddress.address_country}</td>
                </tr>
                <tr>
                  <th>Post Code</th>
                  <td>{selectedAddress.address_postcode}</td>
                </tr>
              </>
            )}
            {selectedAddress.dpid ? (
              <tr>
                <th>Third Party Pick Up</th>
                <td>
                  <input
                    type="checkbox"
                    style={{ width: "auto", marginRight: "10px" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      checkoutBox(event)
                    }
                    id="pickup"
                  />
                  <span style={{ color: "red" }}>
                    *Using a 3rd party to collect this order? Click here.
                  </span>
                </td>
              </tr>
            ) : (
              <tr>
                <th>Confirm your address</th>
                <td>
                  {confirmYourAddress()}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Toast content={toast} onClick={() => setToast(null)} />
      </div>
    );
  };

  switch (addressLoadCode) {
    case 1:
      return renderSavedAddressesForm();
    case 2:
      return <div>No addresses found.</div>;
    case 3:
      return <div>Error: Could not load addresses.</div>;
    default:
      return <LoadingSpinner />;
  }
};

export default AuthenticationHOC(AddressBookNZ);
