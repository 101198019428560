import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { generateAuthHeader } from "../../../../commonfunctions/authentication";
import FieldGenerator from "../../../../components/Address/AddressFormFieldGenerator";
import { getStates } from "../../../../components/Address/states";
import {
  getAddressParams,
  getCountry,
  isAddressValid,
} from "../../../../components/Address/util";
import AuthenticationHOC from "../../../../components/AuthenticationHOC/AuthenticationHOC";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {
  StyledButton,
  StyledFormLayout,
  Wrapper,
} from "../../../../components/styled/styled-components";
import Toast from "../../../../components/Toast/Toast";
import { IContext, Context } from "../../../../context/ContextApp";
import { AlertType } from "../../../../interfaces/enums";
import { AlertContent } from "../../../../interfaces/interfaces";
import { getAddressDetail } from "../../../check-out/shipping-selection/AU/AddressOptions/NewAddress/GooglePlaceAPI";
import { Address as AddressSearcher } from "../../../../components/Address/AddressSearcher";

interface Props {
  setEditMode: (b: boolean) => void;
}

interface Form {
  state?: string;
  ship_to_name: string;
  address_line1: string;
  address_line2: string;
  address_line3: string;
  address_line4: string;
  address_city: string;
  address_postcode: string;
  address_country: string;
  is_primary_address?: boolean;
  dpid?: number;
}

const NewAddress = ({ setEditMode }: Props) => {
  const { appBranch }: IContext = useContext(Context);
  const initialFormState: Form = {
    ship_to_name: "",
    address_line1: "",
    address_line2: "",
    address_line3: "",
    address_line4: "",
    address_city: "",
    address_postcode: "",
    // This field should be immutable
    address_country: getCountry(appBranch),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<Form>(initialFormState);
  const [toast, setToast] = useState<AlertContent | null>(null);

  useEffect(() => {
    /* Initialise state selector */
    const tempForm = form;
    if (appBranch !== "nz") {
      tempForm["state"] = getStates(appBranch)[0].value;
    }
    setForm(tempForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAddressSearcher = (object: any): object is AddressSearcher =>
    "FullAddress" in object;

  const onFieldChange = async (
    event: React.ChangeEvent<HTMLInputElement> | AddressSearcher,
    key: string
  ) => {
    // If we're setting the address returned from the address searcher
    if (isAddressSearcher(event)) {
      if (appBranch === "au") {
        const addressDetail = await getAddressDetail(event?.placeId || "");
        setForm({
          ...form,
          [key]:
            event.FullAddress.charAt(0).toUpperCase() +
            event.FullAddress.slice(1),
          address_city: addressDetail.city,
          address_postcode: addressDetail.post_code,
          state: addressDetail.state,
        });
      }
      if (appBranch === "nz") {
        const formNZ = getAddressParams(event.FullAddress, form.ship_to_name);
        const newForm = {
          ...form,
          address_line1: formNZ ? formNZ.address_line1 : "",
          address_line4: formNZ ? formNZ.address_line4 : "",
          address_city: formNZ ? formNZ.address_city : "",
          address_postcode: formNZ ? formNZ.address_postcode : "",
          state: formNZ ? formNZ.state : "",
        }
        if (event.DPID) {
          newForm.dpid = event.DPID;
        }
        setForm(newForm);
      }
      // Else if setting ship_to_name
    } else {
      setForm({
        ...form,
        [key]: event.target.value,
      });
    }
  };

  const saveAddress = () => {
    if (!isAddressValid(form, appBranch)) {
      setToast({
        message: "Please fill in all fields.",
        type: AlertType.Error,
      });
      return;
    }

    const {
      ship_to_name,
      address_line1,
      address_line2,
      address_line3,
      address_line4,
      address_city,
      address_postcode,
      address_country,
      state,
      dpid,
    } = form;

    const params = {
      ship_to_name: ship_to_name ?? "",
      address_line1: address_line1 ?? "",
      address_line2: address_line2 ?? "",
      address_line3: address_line3 ?? "",
      address_line4: address_line4 ?? "",
      address_city: address_city ?? "",
      address_state: state,
      address_country,
      address_postcode: address_postcode ?? "",
      dpid,
    };

    const req_url = `${process.env.REACT_APP_HALFBACK_ROOT_API}/ship_to_addresses`;

    setIsLoading(true);

    axios
      .post(req_url, params, generateAuthHeader())
      .then((response) => {
        if (response.status === 201) {
          setToast({
            message: "Address added successfully.",
            type: AlertType.Success,
          });
        } else {
          setToast({
            message: "Something went wrong, please try again later",
            type: AlertType.Error,
          });
        }
        setIsLoading(false);
        setEditMode(true);
      })
      .catch(() => {
        setIsLoading(false);
        setToast({
          message: "Something went wrong, please try again later",
          type: AlertType.Error,
        });
      });
  };

  return (
    <StyledFormLayout>
      <FieldGenerator form={form} onChange={onFieldChange} />
      {isLoading ? (
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      ) : (
        <StyledButton onClick={() => saveAddress()}>Add</StyledButton>
      )}
      <Toast content={toast} onClick={() => setToast(null)} />
    </StyledFormLayout>
  );
};

export default AuthenticationHOC(NewAddress);
