import { subdomainTypes } from "../../context/availableSubdomains";
import { Address } from "../../interfaces/interfaces";

export const formatDisplayAddress = (address: Address) => {
  if (!address) {
    return "----------------";
  }

  let display = "";
  display += address.ship_to_name;

  if (address.address_line1) {
    display += ", " + address.address_line1;
  }
  if (address.address_line4) {
    display += ", " + address.address_line4;
  }
  if (address.address_city) {
    display += ", " + address.address_city;
  }
  if (address.address_postcode) {
    display += ", " + address.address_postcode;
  }
  return display;
};

export const formatShipParamsForSave = (address: Address, appBranch: subdomainTypes | '') => {
  let { address_line2, address_line3, address_line4, address_country } =
    address;

  const { address_city, address_postcode, dpid } = address;

  if (appBranch === "nz") {
    address_line2 = "";
    address_line3 = "";
    address_country = "NZ";
  } else if (appBranch === "au") {
    address_country = "AUSTRALIA";
  } else if (appBranch === "ca") {
    address_line3 = "";
    address_line4 = "";
    address_country = "CANADA";
  }

  const shipAddressParamsForSave = {
    address_id: address.id,
    ship_to_name: address.ship_to_name,
    address_line1: address.address_line1,
    address_line2,
    address_line3,
    address_line4,
    address_city,
    address_state: address.state,
    address_country,
    address_postcode,
    dpid
  };

  return shipAddressParamsForSave;
};

export const getCountry = (appBranch: subdomainTypes | '') => {
  const dictionary = {
    "au": "AUSTRALIA",
    "nz": "NEW ZEALAND",
    "ca": "CANADA",
    "us": "UNITED STATES",
    "": "N/A"
  };

  return dictionary[appBranch] ?? "N/A";
};

const isFieldValid = (value?: string | null) => value && value.trim().length > 0;

export const isAddressValid = (address: Address, appBranch: subdomainTypes | '') => {
  const {
    ship_to_name,
    address_line1,
    address_line2,
    // address_line3,
    // address_line4,
    address_city,
    address_postcode,
    state,
  } = address;

  if (appBranch === "nz") {
    return (
      address &&
      isFieldValid(ship_to_name) &&
      isFieldValid(address_line1) &&
      isFieldValid(address_city) &&
      isFieldValid(address_postcode)
    );
  } else if (appBranch === "au") {
    return (
      address &&
      isFieldValid(ship_to_name) &&
      isFieldValid(address_line1) &&
      isFieldValid(address_city) &&
      isFieldValid(state) &&
      isFieldValid(address_postcode)
    );
  } else if (appBranch === "ca") {
    return (
      address &&
      isFieldValid(ship_to_name) &&
      isFieldValid(address_line1) &&
      isFieldValid(address_line2) &&
      isFieldValid(address_city) &&
      isFieldValid(state) &&
      isFieldValid(address_postcode)
    );
  }
};

export const getAddressParams = (address: string, shipToName: string) => {
  let shipAddressParams = null;
  if (address.includes("!")) {
    const addray = address.split("! ");

    if (addray.length === 5) {

      shipAddressParams = {
        ship_to_name: shipToName,
        address_line1: addray[0],
        address_line2: "",
        address_line3: "",
        address_line4: addray[1],
        address_city: addray[2],
        state: addray[3],
        address_country: "NZ",
        address_postcode: addray[4],
        is_primary_address: false,
      }; // (e.g: '11 Hautu Drive, Wiri, Auckland, 2104') suburb is 'Wiri' and city is 'Auckland'
    }
  } else {

    const addray = address.split(", ");

    if (addray.length === 3) {
      const indexer = addray[2].lastIndexOf(" ");
      const city = addray[2].substring(0, indexer);
      const postcode = addray[2].substring(indexer + 1);

      let state = "SI";
      if (parseInt(postcode.charAt(0)) < 7) {
        state = "NI";
      }

      shipAddressParams = {
        ship_to_name: shipToName,
        address_line1: addray[0],
        address_line2: "",
        address_line3: "",
        address_line4: addray[1],
        address_city: city,
        state: state,
        address_country: "NZ",
        address_postcode: postcode,
        is_primary_address: false,
      }; // (e.g: '9 Gold Street, Albany Heights, Auckland 0632') suburb is 'Albany Heights' and city is 'Auckland'
    } else if (addray.length === 2) {
      const fullArray = addray[1].split(" ");
      const postcode = fullArray[fullArray.length - 1];
      const city = addray[1].substring(0, addray[1].indexOf(postcode)).trim();

      let state = "SI";
      if (parseInt(postcode.charAt(0)) < 7) {
        state = "NI";
      }

      shipAddressParams = {
        ship_to_name: shipToName,
        address_line1: addray[0],
        address_line2: "",
        address_line3: "",
        address_line4: city,
        address_city: city,
        state: state,
        address_country: "NZ",
        address_postcode: postcode,
        is_primary_address: false,
      }; // (e.g: '9 Gold Street, Waitara 4320') suburb and city will be the same 'Waitara'
    }
  }

  return shipAddressParams;

};
